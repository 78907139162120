import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "normalize.css"

// Toast style
import "vant/es/toast/style";
// Dialog style
import "vant/es/dialog/style";
// Notify style
import "vant/es/notify/style";
// ImagePreview style
// import 'vant/es/image-preview/style';

const app = createApp(App);
app.use(store).use(router).mount("#app");
app.config.isCustomElement = (tag) => tag === "wx-open-launch-weapp";
