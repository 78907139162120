import { httpRequest } from "@/request";

// 获取验证码(登录)
export const apiGetVCode = (params: any) =>
  httpRequest.post("/login/sendVerificationCode", {}, params);

// 登录
export const apiLogin = (data: any) =>
  httpRequest.post("/login/getToken", data);

// 获取手机号
export const apiGetPhoneNumber = () => httpRequest.post("/consumer/getPhoneNo");

// 获取号卡列表
export const apiGetCardList = () => httpRequest.post("/card/V3/card/list");

// 退出登录
export const apiLogout = () => httpRequest.get("/logout/logout");

// 获取APP配置
export const apiGetAppConfig = () => httpRequest.get("/appConfig/get");

// 获取首页图片
export const apiGetHomeImg = () => httpRequest.get("/company/info/v2/get");

// 获取卡信息
export const apiQueryCardMsg = (params) =>
  httpRequest.get("/card/v5/queryCardMsg", params);

export const apiGetInfo = () => httpRequest.get("/company/info/v2/get");

export const apiCheckParticipateCardActivity = (params) =>
  httpRequest.get(`/card/participate/card-activity`, params);

export const apiCheckActivity = (iccid) =>
  httpRequest.get("/card/V3/check/activity", { iccid });

export const apiGetAppVersion = (params) =>
  httpRequest.get("/app/version", params);

export const apiGetPrechargeNoticeInfo = (params) =>
  httpRequest.get("/card/card/tips", params);

export const apiGetPhoneNo = () => httpRequest.post("/consumer/getPhoneNo");

export const apiGetNextBillMsg = (params) =>
  httpRequest.get(`/card/queryNextBillMsg`, params);

export const apiGetNotification = (params) =>
  httpRequest.post(`/card/queryNotification`, params);

export const apiGetCardBalance = (iccid) =>
  httpRequest.get("/card/v4/queryCardBalance", { iccid });

export const apiGetUserBalance = (mobile) =>
  httpRequest.get("/user/account/query", { mobile });

export const apiGetCardDetails = (iccid) =>
  httpRequest.get("/card/V3/card/details", { iccid });

export const apiGetPackage = (iccid) =>
  httpRequest.get("/V3/card/queryPackagesThis", { iccid });

export const apiGetPackageNext = (iccid) =>
  httpRequest.get("/V3/card/queryPackagesNext", { iccid });

export const apiGetBalanceInfo = (data) =>
  httpRequest.post("/order/V3/balance", data);

export const apiGetPackageInfo = (data) =>
  httpRequest.post("/order/V3/package/info", data);

// 取消订单
export const apiCloseOrder = (orderNo) =>
  httpRequest.get("/orderPay/closeOrder", { orderNo });

// 二次支付
export const apiPayAgain = (orderNo) =>
  httpRequest.get("/orderPay/payAgain", { orderNo });

// 获取组合支付配置信息
export const apiGetCompositePayConfig = (iccid) =>
  httpRequest.get("/appConfig/compositePay/config", { iccid });

// 获取APP余额支付配置
export const apiGetPayConfig = (type) =>
  httpRequest.get("/appConfig/pay/config", { type });

// 获取订单状态
export const apiGetOrderStatus = (orderNo) =>
  httpRequest.get("/orderQuery/queryOrderPayStatus", { orderNo });

// 提交订单
export const apiOrderPackage = (data) =>
  httpRequest.post("/orderPay/orderPackage", data);

// 上传实名图片(正面)
export const apiUploadRealNameFront = (data, params) =>
  httpRequest.post(`/realName/frontPhoto`, data, params);

// 上传实名图片(背面)
export const apiUploadRealNameBack = (data, params) =>
  httpRequest.post(`/realName/backPhoto`, data, params);

// 上传实名视频
export const apiUploadRealNameVideo = (data, params) =>
  httpRequest.post(`/realName/doAuthByVideo`, data, params);

// 上传实名视频
export const apiGetReadNum = (params) =>
  httpRequest.get(`/realName/getReadNum`, params);

// 提交订单
export const apiGetCouponList = (data) =>
  httpRequest.post("/order/V3/coupon/usable/list", data);

//流量记录
export const getFlowList = (params: any) =>
  httpRequest.get("/orderQuery/queryOrdersByIccid", params);

//取消订单
export const cancellationOrder = (params: any) =>
  httpRequest.get("/orderPay/closeOrder", params);

// 检查实名信息
export const apiCheckRealNameInfo = (params: any) =>
  httpRequest.post("/realName/infoChek", {}, params);

// 发送验证码(实名)
export const apiSendVerifyCode = (params: any) =>
  httpRequest.get("/realName/sendVerificationCode", params);

// 检查卡板是否可以绑定
export const apiCheckCardBind = (params: any) =>
  httpRequest.get("/card/checkBind", params);

// 绑定卡板
export const apiBindCard = (data: any) => httpRequest.post("/card/bind", data);

// 根据手机号获取iccid
export const apiGetIccid = (params: any) =>
  httpRequest.get("/card/v4/phone/iccid", params);

// 获取微信公众号租户ID
export const apiGetOfficialAccounts = () =>
  httpRequest.post("/v3/wechat/officialAccounts/config");

// 获取微信授权
export const apiGetWXAuthorization = (appId) =>
  httpRequest.post(`/v3/wechat/officialAccounts/auth/${appId}`);

// 获取微信JSApiConfig参数
export const apiGetJSApiConfig = (data) =>
  httpRequest.post(`/v3/wechat/officialAccounts/openJsApi`, data);

// 活动支付
export const apiActivityPay = (data) =>
  httpRequest.post(`/balance/activity/recharge`, data);

// 活动支付
export const apiGetActivityDetail = (params) =>
  httpRequest.get(`/v3/activity/detail`, params);

// 获取地址列表
export const apiGetAreaList = () => httpRequest.post(`/area/simple/tree`);

// 发送验证码
export const apiSendVCode = (params) =>
  httpRequest.get(`/sms/sendVerificationCode`, params);

// 西藏卡预提交
export const apiPreSubmit = (data) =>
  httpRequest.post(`/tibet/order/preSubmission`, data);

// 西藏卡上传身份信息(身份证正面)
export const apiUploadCardFront = (certificate, data) =>
  httpRequest.post(`/tibet/order/uploadCardFront/${certificate}`, data);

// 西藏卡上传身份信息(身份证反面)
export const apiUploadCardBack = (certificate, data) =>
  httpRequest.post(`/tibet/order/uploadCardBack/${certificate}`, data);

// 西藏卡上传身份信息(手持身份证)
export const apiUploadUserPhotoAndDoOrder = (certificate, data) =>
  httpRequest.post(
    `/tibet/order/uploadUserPhotoAndDoOrder/${certificate}`,
    data
  );
