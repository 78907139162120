import { apiGetJSApiConfig } from "@/api";
import store from "@/store";
import { Md5 } from "ts-md5";
import { showToast } from "vant";
import wx from "weixin-js-sdk-ts";

export function getData(key: string) {
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = localStorage.getItem(key);
  return data;
}

export function getToken() {
  return getData("token");
}

export function getIccid() {
  return getData("iccid");
}

export function getShortIccid() {
  return getData("shortIccid");
}

export function getTenantId() {
  const key = "tenantId";
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = sessionStorage.getItem(key);
  return data;
}

export function getCustomerServiceUrl() {
  const key = "customerServiceUrl";
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = sessionStorage.getItem(key);
  return data;
}

export function getWXAppId() {
  const key = "wxAppId";
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = sessionStorage.getItem(key);
  return data;
}

// export function getPhoneNumber() {
//   return getData("phoneNumber");
// }

export function getPhoneNumber() {
  const key = "phoneNumber";
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = sessionStorage.getItem(key);
  return data;
}
export function getWXConfigId() {
  const key = "wxConfigId";
  const state: any = store.state;
  let data: string | null | undefined = state[key];
  if (data) return data;
  data = sessionStorage.getItem(key);
  return data;
}

export function getUserId() {
  return getData("userId");
}

export function getAppId() {
  return getData("appId");
}

export function getSecretKey() {
  return getData("secretKey");
}

export function getAppPackageName() {
  return getData("appPackageName");
}

export function getVersion() {
  return getData("version");
}

export function getCurrentCardInfo() {
  const dataStr = localStorage.getItem("currentCardInfo");
  if (dataStr) return JSON.parse(dataStr);
  return null;
}

export function getPrechargeSelectedCoupons() {
  const dataStr = localStorage.getItem("PRECHARGE_B_COUPONS_LIST");
  if (dataStr) return JSON.parse(dataStr);
  return null;
}

export function getAutoRenewTime() {
  return localStorage.getItem("AutoRenewTime");
}

export function getAppConfig() {
  const dataStr = localStorage.getItem("APPConfig");
  if (dataStr) return JSON.parse(dataStr);
  return null;
}

export function setAutoRenewTime(data) {
  return localStorage.setItem("AutoRenewTime", data);
}

export function getOrderSource(orderPayType) {
  const orderSources = getAppConfig()?.orderSources;
  if (orderSources) {
    const sourceIndex = orderSources.findIndex(
      (item) => item.orderPayType === orderPayType
    );
    if (sourceIndex !== -1) {
      return orderSources[sourceIndex];
    }
  }
  return null;
}

// 随机字符串
export function getRandomString() {
  const RandomString: string[] = [];
  const randoms = "0123456789abcdef";
  for (let i = 0; i < 10; i++) {
    RandomString[i] = randoms.substr(Math.floor(Math.random() * 0x10), 1);
  }
  RandomString[8] = "-";
  return RandomString.join("");
}

// 获取时间戳
export function getTimeStamp() {
  return new Date().getTime();
}

// 参数排序
export function keySort(params) {
  const newkey = Object.keys(params).sort();
  const newObj = {};
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = params[newkey[i]];
  }
  return newObj;
}

// 参数过滤
export function filterData(obj: { [key: string]: any } | null | undefined) {
  if (obj === null) return {};
  if (obj === undefined) return {};
  const newObj = {};
  Object.keys(obj)
    .filter((item) => obj[item] !== null)
    .forEach((item) => Object.assign(newObj, { [item]: obj[item] }));
  return newObj;
}

//
export function getParamStr(params) {
  const searchParams = new URLSearchParams(keySort(filterData(params)));
  const paramArr: string[] = [];
  for (const pair of searchParams.entries()) {
    paramArr.push(`${pair[0]}=${pair[1]}`);
  }
  return paramArr.join("&");
}

// 获取签名(验签)
export function getSign(params: any, nonce: string, timeStamp: number) {
  const paramStr = getParamStr(params);
  const sourceStr = `${getSecretKey()}&${paramStr}&${nonce}&${timeStamp}&${getAppId()}`;
  return Md5.hashStr(sourceStr);
}

// 身份证号码验证
export function checkIdCard(value) {
  const codeStr = value;
  const city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 ",
  };
  let tip = "";
  let pass = true;
  if (
    !codeStr ||
    !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(
      codeStr
    )
  ) {
    tip = "身份证号格式错误";
    pass = false;
  } else if (!city[codeStr.substr(0, 2)]) {
    tip = "地址编码错误";
    pass = false;
  } else {
    //18位身份证需要验证最后一位校验位
    if (codeStr.length == 18) {
      const code = codeStr.split("");
      //∑(ai×Wi)(mod 11)
      //加权因子
      const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;
      let ai = 0;
      let wi = 0;
      for (let i = 0; i < 17; i++) {
        ai = Number(code[i]);
        wi = factor[i];
        sum += ai * wi;
      }
      const last = parity[sum % 11];
      if (parity[sum % 11] != code[17]) {
        tip = "校验位错误";
        pass = false;
      }
    }
  }
  console.log("身份证号", tip);
  return pass;
}

export function wxApiConfig(jsApiList, openTagList: any[] = []) {
  const data = {
    id: getWXConfigId(),
    url: window.location.href,
  };
  apiGetJSApiConfig(data).then((res) => {
    wx.config({
      // debug: true,
      appId: res.result.appId,
      timestamp: res.result.timestamp,
      nonceStr: res.result.nonceStr,
      signature: res.result.signature,
      jsApiList, // 必填，需要使用的 JS 接口列表
      openTagList,
    });
    wx.ready(() => {
      console.log("wxConfig ready");
    });
    wx.error((err: any) => {
      // showNotify({ type: "warning", message: err });
      showToast({ message: JSON.stringify(err) });
    });
  });
}
