import { apiGetAppConfig, apiGetOfficialAccounts, apiGetPhoneNo } from "@/api";
import store from "@/store";
import {
  getAppConfig,
  getCustomerServiceUrl,
  getPhoneNumber,
  getTenantId,
  getWXAppId,
} from "@/utils/auth";
import { showFailToast, showNotify } from "vant";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    // 首页
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    // 我的钱包
    path: "/myWallet",
    name: "myWallet",
    component: () =>
      import(/* webpackChunkName: "myWallet" */ "../views/MyWallet.vue"),
  },
  {
    // 个人中心
    path: "/userCenter",
    name: "userCenter",
    component: () =>
      import(/* webpackChunkName: "userCenter" */ "../views/UserCenter.vue"),
  },
  {
    // 套餐订购记录
    path: "/packageRecord",
    name: "packageRecord",
    component: () =>
      import(
        /* webpackChunkName: "packageRecord" */ "../views/PackageRecord.vue"
      ),
  },
  {
    // 客服
    path: "/service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/ServiceView.vue"),
  },
  {
    // 实名页面
    path: "/realName",
    name: "realName",
    component: () =>
      import(/* webpackChunkName: "realName" */ "../views/RealName.vue"),
  },
  {
    // 实名页面
    path: "/realNameView",
    name: "realNameView",
    component: () =>
      import(
        /* webpackChunkName: "realNameView" */ "../views/RealNameModules/RealName.vue"
      ),
  },
  {
    // 订购流量
    path: "/orderFlow",
    name: "orderFlow",
    component: () =>
      import(/* webpackChunkName: "orderFlow" */ "../views/OrderFlow.vue"),
    meta: { keepAlive: true },
  },
  {
    // 套餐列表(选择套餐)
    path: "/packageList",
    name: "packageList",
    component: () =>
      import(/* webpackChunkName: "packageList" */ "../views/PackageList.vue"),
  },
  {
    // 充值成功(卡内)
    path: "/rechargeResult/card",
    name: "rechargeResultCard",
    component: () =>
      import(
        /* webpackChunkName: "rechargeResultCard" */ "../views/RechargeResultCard.vue"
      ),
  },
  {
    // 充值成功(通用)
    path: "/rechargeResult/universal",
    name: "rechargeResultUniversal",
    component: () =>
      import(
        /* webpackChunkName: "rechargeResultUniversal" */ "../views/RechargeResultUniversal.vue"
      ),
  },
  {
    // 订购成功
    path: "/orderSuccess",
    name: "orderSuccess",
    component: () =>
      import(
        /* webpackChunkName: "orderSuccess" */ "../views/OrderSuccess.vue"
      ),
  },
  {
    // 活动中心
    path: "/activityCenter",
    name: "activityCenter",
    component: () =>
      import(
        /* webpackChunkName: "activityCenter" */ "../views/ActivityCenter.vue"
      ),
  },
  {
    // 绑卡
    path: "/bindCard",
    name: "bindCard",
    component: () =>
      import(/* webpackChunkName: "bindCard" */ "../views/BindCard.vue"),
  },
  {
    // 登录
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    // 用户协议
    path: "/userAgreement",
    name: "userAgreement",
    component: () =>
      import(
        /* webpackChunkName: "userAgreement" */ "../views/UserAgreement.vue"
      ),
  },
  {
    // 隐私协议
    path: "/privacyAgreement",
    name: "privacyAgreement",
    component: () =>
      import(
        /* webpackChunkName: "privacyAgreement" */ "../views/PrivacyAgreement.vue"
      ),
  },
  {
    // 自动续订服务协议
    path: "/supplementArgument",
    name: "supplementArgument",
    component: () =>
      import(
        /* webpackChunkName: "supplementArgument" */ "../views/SupplementArgument.vue"
      ),
  },
  {
    // 选择优惠券
    path: "/selectCoupon",
    name: "selectCoupon",
    component: () =>
      import(
        /* webpackChunkName: "selectCoupon" */ "../views/SelectCoupon.vue"
      ),
  },
  {
    //个人中心
    path: "/mine",
    name: "mine",
    component: () => import("@/views/myPage/mineIndex.vue"),
  },
  {
    //普通卡充值
    path: "/ordinaryCardRecharge",
    name: "ordinaryCardRecharge",
    component: () => import("@/views/myPage/myWallet/ordinaryCardRecharge.vue"),
  },
  {
    //余额明细
    path: "/balanceRecord",
    name: "balanceRecord",
    component: () => import("@/views/myPage/myWallet/balanceRecord.vue"),
  },
  {
    //我的钱包
    path: "/myWallet",
    name: "myWallet",
    component: () => import("@/views/myPage/myWallet/myWallet.vue"),
  },
  {
    //更换卡片
    path: "/replacementCard",
    name: "replacementCard",
    component: () => import("@/views/myPage/myWallet/replacementCard.vue"),
  },
  {
    //充值成功
    path: "/rechargeSucceed",
    name: "rechargeSucceed",
    component: () => import("@/views/myPage/myWallet/rechargeSucceed.vue"),
  },
  {
    //预充值卡充值
    path: "/beforeCardRecharge",
    name: "beforeCardRecharge",
    component: () => import("@/views/myPage/myWallet/beforeCardRecharge.vue"),
  },
  {
    //预充值卡余额
    path: "/beforeRechargeCardBalance",
    name: "beforeRechargeCardBalance",
    component: () =>
      import("@/views/myPage/myWallet/beforeRechargeCardBalance.vue"),
  },
  {
    //设置页面
    path: "/settingView",
    name: "settingView",
    component: () => import("@/views/myPage/myWallet/settingView.vue"),
  },
  {
    //反馈意见
    path: "/feedbackView",
    name: "feedbackView",
    component: () => import("@/views/myPage/myWallet/feedbackView.vue"),
  },
  // {
  //   //隐私政策
  //   path: "/privacyPolicy",
  //   name: "privacyPolicy",
  //   component: () =>
  //     import("@/views/myPage/myWallet/setting/privacyPolicy.vue"),
  // },
  // {
  //   //userArgument 用户协议
  //   path: "/userArgument",
  //   name: "userArgument",
  //   component: () => import("@/views/myPage/myWallet/setting/userArgument.vue"),
  // },
  {
    //关于我们
    path: "/about",
    name: "about",
    component: () => import("@/views/myPage/myWallet/setting/about.vue"),
  },
  {
    //活动列表
    path: "/activityList",
    name: "activityList",
    component: () => import("@/views/myPage/myWallet/activityList.vue"),
  },
  {
    //西藏卡开卡
    path: "/orderCard",
    name: "orderCard",
    component: () => import("@/views/OrderCard.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const whiteList: string[] = [
  "/login",
  "/userAgreement",
  "/privacyAgreement",
  "/supplementArgument",
];

function checkWhiteList(path: string) {
  return whiteList.includes(path);
}

router.beforeEach(async (to, from, next) => {
  if (!getTenantId() || !getWXAppId() || !getCustomerServiceUrl()) {
    const result = await apiGetOfficialAccounts();
    if (result.code !== 200) {
      // showNotify({ type: "danger", message: result.message, duration: 0 });
      showFailToast({ message: result.message, duration: 0 });
      return;
    }
    const { appId, tenantId, customerServiceUrl, id } = result.result;
    store.commit("setWXConfigId", id);
    store.commit("setWXAppId", appId);
    store.commit("setTenantId", tenantId);
    store.commit("setCustomerServiceUrl", customerServiceUrl);
  }
  if (checkWhiteList(to.path)) {
    next();
    return;
  }
  if (!getPhoneNumber()) {
    apiGetPhoneNo().then((res) => {
      if (res.code === 200) store.commit("setPhoneNumber", res.result);
    });
  }
  if (!getAppConfig()) {
    apiGetAppConfig().then((res) => {
      if (res.code === 200) store.commit("setAppConfig", res.result);
    });
  }
  next();
});

export default router;
