import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
// import router from "@/router";
import { closeToast, showLoadingToast, showToast } from "vant";
import {
  getAppId,
  getAppPackageName,
  getRandomString,
  getSign,
  getTenantId,
  getTimeStamp,
  getToken,
  getVersion,
} from "@/utils/auth";
import router from "@/router";

let loadingCount = 0;
class Request {
  private baseUrl =
    process.env.VUE_APP_PROXY_PATH + process.env.VUE_APP_BASE_PATH;

  private request(opts: AxiosRequestConfig): any {
    const instance: AxiosInstance = axios.create({
      baseURL: this.baseUrl,
      timeout: 10000,
    });
    this.setInterceptors(instance);
    return instance(opts);
  }
  //拦截器
  private setInterceptors(instance: AxiosInstance) {
    loadingCount++;
    if (loadingCount > 0) {
      showLoadingToast({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
    }
    //请求拦截器
    instance.interceptors.request.use((config) => {
      const nonce = getRandomString();
      const timeStamp = getTimeStamp();
      const { data, params, method } = config;

      config.headers.OfficialAccounts = "APP";
      config.headers.appId = getAppId(); // APP_ID
      config.headers.nonce = nonce; // 随机字符串
      config.headers.timeStamp = timeStamp; // 毫秒时间戳
      const paramsData = method === "post" ? data : params;
      config.headers.sign = getSign(paramsData, nonce, timeStamp); // 签名
      config.headers.token = getToken(); // TOKEN
      config.headers.appPackageName = getAppPackageName(); // app包名
      config.headers.version = getVersion(); // 版本号, 固定 v3.0
      config.headers.tenant = getTenantId(); // 租户ID
      return config;
    });
    //响应拦截器
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        loadingCount--;
        if (loadingCount <= 0) {
          closeToast();
        }
        if (res.status == 200) {
          const { code } = res.data;
          switch (code) {
            case 200:
            case 214:
              return Promise.resolve(res.data);
            case 401:
            case 4003:
              showToast("用户信息过期，请重新登录");
              setTimeout(() => {
                router.push("/login");
              }, 1000);
              return Promise.resolve(res.data);
            default:
              console.warn(`status= ${res.status}`);
              return Promise.resolve(res.data);
          }
        } else {
          return Promise.reject(res.data);
        }
      },
      (err) => {
        loadingCount--;
        if (loadingCount <= 0) {
          closeToast();
        }
        return Promise.reject(err);
      }
    );
  }

  //封装get请求
  public get(url: string, params?: any) {
    const method = "get";
    return this.request({ url, method, params });
  }

  //封装post请求
  public post(url: string, data?: any, params?: any) {
    const method = "post";
    return this.request({ url, method, data, params });
  }

  //封装put请求
  public put(url: string, data?: any, params?: any) {
    const method = "put";
    return this.request({ url, method, data, params });
  }
}
export const httpRequest = new Request();
