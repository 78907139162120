import { createStore } from "vuex";

export default createStore({
  state: {
    iccid: "",
    shortIccid: "",
    tenantId: "",
    userId: "",
    token: "",
    appId: "OCj9U2zFCmR70B9B",
    appPackageName: "com.rzcf.app",
    version: "v3.0",
    secretKey: "K8OognCxb9QzV6RWqugpdphSFD4LkNxP", //secret
    currentCardInfo: null,
    phoneNumber: "",
    APPConfig: null,
    bPrechargeSelectedCouponsList: null,
    wxAppId: "",
    wxConfigId: "",
    customerServiceUrl: "",
  },
  getters: {},
  mutations: {
    setIccid(state, data) {
      state.iccid = data;
      localStorage.setItem("iccid", data);
    },
    setShortIccid(state, data) {
      state.shortIccid = data;
      localStorage.setItem("shortIccid", data);
    },
    setTenantId(state, data) {
      state.tenantId = data;
      sessionStorage.setItem("tenantId", data);
    },
    setCustomerServiceUrl(state, data) {
      state.customerServiceUrl = data;
      sessionStorage.setItem("customerServiceUrl", data);
    },
    setWXConfigId(state, data) {
      state.wxConfigId = data;
      sessionStorage.setItem("wxConfigId", data);
    },
    setWXAppId(state, data) {
      state.wxAppId = data;
      sessionStorage.setItem("wxAppId", data);
    },
    setUserId(state, data) {
      state.userId = data;
      localStorage.setItem("userId", data);
    },
    setAppId(state, data) {
      state.appId = data;
      localStorage.setItem("appId", data);
    },
    setToken(state, data) {
      state.token = data;
      localStorage.setItem("token", data);
    },
    removeToken(state) {
      state.token = "";
      localStorage.removeItem("token");
    },
    setCurrentCardInfo(state, data) {
      state.currentCardInfo = data;
      localStorage.setItem("currentCardInfo", JSON.stringify(data));
    },
    setPhoneNumber(state, data) {
      state.phoneNumber = data;
      sessionStorage.setItem("phoneNumber", data);
    },
    setAppConfig(state, data) {
      state.APPConfig = data;
      localStorage.setItem("APPConfig", JSON.stringify(data));
    },

    setSelectedCoupons(state, couponsList) {
      state.bPrechargeSelectedCouponsList = couponsList;
      localStorage.setItem(
        "PRECHARGE_B_COUPONS_LIST",
        JSON.stringify(couponsList)
      );
    },
    removeSelectedCoupons(state) {
      state.bPrechargeSelectedCouponsList = null;
      localStorage.removeItem("PRECHARGE_B_COUPONS_LIST");
    },
    logout(state) {
      state.token = "";
      state.userId = "";
      state.wxAppId = "";
      state.appId = "";
      state.currentCardInfo = null;
      state.phoneNumber = "";
      state.APPConfig = null;
      state.bPrechargeSelectedCouponsList = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      sessionStorage.removeItem("wxAppId");
      localStorage.removeItem("appId");
      localStorage.removeItem("currentCardInfo");
      sessionStorage.removeItem("phoneNumber");
      localStorage.removeItem("APPConfig");
    },
  },
  actions: {},
  modules: {},
});
