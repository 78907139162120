import router from "@/router";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { showNotify, showSuccessToast } from "vant";
import { RouteLocationRaw } from "vue-router";

// 复制
export function copy(val: any) {
  toClipboard(val).then(() => {
    // showNotify({ type: "success", message: "复制成功！", duration: 2000 });
    showSuccessToast({
      type: "success",
      message: "复制成功！",
      duration: 2000,
    });
  });
}

export function clearRouters() {
  const backLen = window.history.length - 1;
  window.history.go(-backLen);
}

// 判断是否微信环境
export function isWeChat(): boolean {
  const ua: string = window.navigator.userAgent.toLowerCase(); // 不加window部分Android机会显示不了提示图，即判断不了是否是微信
  const match: RegExpMatchArray | null = ua.match(/MicroMessenger/i);
  return !!match && match[0] == "micromessenger";
}

export function isIos(): boolean {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

export function push(
  option: string | { path: string; query?: { [key: string]: any } }
) {
  if (typeof option === "string") {
    const url = `${window.location.origin}${option}`;
    if (isIos()) window.location.href = url;
    else router.push(option);
  } else {
    const { path, query } = option;
    const searchParams = new URLSearchParams(query);
    const url = `${window.location.origin}${path}?${searchParams.toString()}`;
    if (isIos()) window.location.href = url;
    else router.push(option);
  }
}

export function replace(
  option: string | { path: string; query?: { [key: string]: any } }
) {
  if (typeof option === "string") {
    const url = `${window.location.origin}${option}`;
    if (isIos()) window.location.href = url;
    else router.replace(option);
  } else {
    const { path, query } = option;
    const searchParams = new URLSearchParams(query);
    const url = `${window.location.origin}${path}?${searchParams.toString()}`;
    if (isIos()) window.location.href = url;
    else router.replace(option);
  }
}
